import { Inbox } from '@trycourier/react-inbox';
import { CourierProvider } from '@trycourier/react-provider';
import { Toast } from '@trycourier/react-toast';
import React, { ReactNode, useMemo, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Roles } from '@clh/api-client';
import { useRoles } from '@clh/ui';

import { useCourierAuthToken } from '../hooks/use-courier-auth-token';
import useIsDesktop from '../hooks/use-is-desktop';
import { getInitials, useSession } from '../hooks/use-session';
import { ImpersonateButton } from '../impersonate';
import Nav from '../shared-components/nav';
import { getAvatarStyles } from '../utils';

import ActionNeeded from './action-needed';

import './header.less';

export const Header: React.FC = function Header() {
    const session = useSession();
    const { roles } = useRoles();

    const { courierAuthToken } = useCourierAuthToken();

    const location = useLocation();
    const isDesktop = useIsDesktop();

    const avatar = session.currentUser?.avatar;
    const initials = getInitials(session);
    const mobileNavRef = useRef<HTMLDivElement>(null);

    const isFacilityAdmin =
        roles.includes(Roles.FacilityAdministrator) && !!session.currentUser;
    const isNurse = roles.includes(Roles.Nurse);

    const currentUserId = sessionStorage.getItem('currentUserId');

    const { Wrapper, inbox, toast } = useMemo(() => {
        if (courierAuthToken && session.currentUser?.id) {
            return {
                Wrapper: ({ children }: { children: ReactNode }) => (
                    <CourierProvider
                        userId={session.currentUser!.id}
                        authorization={courierAuthToken}
                    >
                        {children}
                    </CourierProvider>
                ),
                inbox: (
                    <Inbox
                        openLinksInNewTab={false}
                        views={[
                            {
                                id: 'messages',
                                label: 'Notifications',
                            },
                            {
                                id: 'preferences',
                                label: 'Preferences',
                            },
                        ]}
                    />
                ),
                toast: (
                    <Toast openLinksInNewTab={false} position="bottom-left" />
                ),
            };
        }

        return {
            Wrapper: ({ children }: { children: ReactNode }) => <>{children}</>,
            inbox: null,
            toast: null,
        };
    }, [courierAuthToken, session.currentUser?.id]);

    const handleMobileNavClick = () => {
        mobileNavRef.current?.classList.remove('show');
    };

    return (
        <Wrapper>
            {toast}
            {currentUserId && session.currentUser && <ImpersonateButton />}
            <nav className="navbar navbar-expand-lg bg-body-tertiary border-bottom">
                <div className="container-fluid">
                    <Link
                        className="navbar-brand d-flex align-items-center"
                        to={
                            session.currentUser
                                ? isFacilityAdmin
                                    ? '/site/facility/shifts'
                                    : '/site/browse-shifts'
                                : '/'
                        }
                    >
                        <span className="call-light-logo me-lg-2"></span>
                    </Link>
                    <div className="d-flex align-items-center">
                        {isDesktop ? null : (
                            <div className="me-3 z-index-1">{inbox}</div>
                        )}
                        <button
                            className="navbar-toggler btn btn-link text-reset p-0 border-0 lh-sm dropdown-toggle fs-6 flex align-items-center text-decoration-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#nav"
                            aria-controls="nav"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            {avatar ? (
                                <div
                                    className="rounded-circle d-inline-block"
                                    style={getAvatarStyles(avatar, {
                                        width: 40,
                                        height: 40,
                                    })}
                                />
                            ) : (
                                <div className="call-light-header-right-circle d-inline-block">
                                    {initials || (
                                        <i className="bi bi-person-circle"></i>
                                    )}
                                </div>
                            )}
                        </button>
                    </div>
                    <div
                        className="collapse navbar-collapse"
                        id="nav"
                        ref={mobileNavRef}
                    >
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0 display-flex align-items-center">
                            <Nav.Link
                                className="dropdown-item d-block d-lg-none"
                                isSelected={location.pathname.includes(
                                    '/profile'
                                )}
                                title={isNurse ? 'Profile List' : 'Profile'}
                                uri={
                                    isNurse
                                        ? session.currentUser
                                            ? '/site/profile/list'
                                            : '/site/profile'
                                        : '/site/facility-admin/profile'
                                }
                                onClick={handleMobileNavClick}
                            />
                            {isNurse ? (
                                <>
                                    <Nav.Link
                                        className="me-lg-2"
                                        uri="/site/browse-orientations"
                                        title="Browse Orientations"
                                        isSelected={location.pathname.includes(
                                            '/browse-orientations'
                                        )}
                                        onClick={handleMobileNavClick}
                                    />
                                    <Nav.Link
                                        className="me-lg-2 me-lg-0"
                                        uri="/site/browse-shifts"
                                        title="Browse Shifts"
                                        isSelected={location.pathname.includes(
                                            '/browse-shifts'
                                        )}
                                        onClick={handleMobileNavClick}
                                    />
                                    <Nav.Link
                                        className="me-lg-2"
                                        uri="/site/my-schedule"
                                        title="My Schedule"
                                        isSelected={location.pathname.includes(
                                            '/my-schedule'
                                        )}
                                        onClick={handleMobileNavClick}
                                    />
                                    <Nav.Link
                                        className="me-lg-2"
                                        uri="/site/my-facilities"
                                        title="My Facilities"
                                        isSelected={location.pathname.includes(
                                            '/my-facilities'
                                        )}
                                        onClick={handleMobileNavClick}
                                    />
                                    <Nav.Link
                                        className="me-lg-2"
                                        uri="/site/my-history"
                                        title="My History"
                                        isSelected={location.pathname.includes(
                                            '/my-history'
                                        )}
                                        onClick={handleMobileNavClick}
                                    />
                                    <Nav.Link
                                        className="me-lg-2"
                                        isAnchor
                                        uri="https://humla.outseta.com/support/kb/categories"
                                        title="FAQ"
                                        onClick={handleMobileNavClick}
                                        target="_blank"
                                        rel="noreferrer"
                                    />
                                </>
                            ) : (
                                <Nav.Link
                                    className="me-lg-2"
                                    isAnchor
                                    uri="https://humla.outseta.com/support/kb/categories"
                                    title="FAQ"
                                    onClick={handleMobileNavClick}
                                    target="_blank"
                                    rel="noreferrer"
                                />
                            )}
                            <Nav.Link
                                className="d-block d-lg-none"
                                uri="/site/sign-out"
                                title="Log Out"
                                onClick={handleMobileNavClick}
                            />
                            {isDesktop ? (
                                <li className="nav-item dropdown d-block me-lg-2">
                                    <div className="nav-link">{inbox}</div>
                                </li>
                            ) : null}
                            <li className="nav-item dropdown d-none d-lg-block">
                                <button
                                    className="nav-link dropdown-toggle text-reset btn btn-link text-reset p-0 border-0 lh-sm d-flex align-items-center"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    type="button"
                                >
                                    {avatar ? (
                                        <div
                                            className="rounded-circle d-inline-block"
                                            style={getAvatarStyles(avatar, {
                                                width: 40,
                                                height: 40,
                                            })}
                                        />
                                    ) : (
                                        <div className="call-light-header-right-circle d-inline-block">
                                            {initials || (
                                                <i className="bi bi-person-circle"></i>
                                            )}
                                        </div>
                                    )}
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end">
                                    <li>
                                        <Link
                                            className="dropdown-item"
                                            to={
                                                isNurse
                                                    ? session.currentUser
                                                        ? '/site/profile/personal-information'
                                                        : '/site/profile'
                                                    : '/site/facility-admin/profile'
                                            }
                                        >
                                            Profile
                                        </Link>
                                    </li>
                                    <li>
                                        {currentUserId ? (
                                            <a
                                                className="dropdown-item"
                                                href={
                                                    '/site/stop-impersonation'
                                                }
                                            >
                                                Stop Impersonating
                                            </a>
                                        ) : (
                                            <Link
                                                className="dropdown-item"
                                                to="/site/sign-out"
                                            >
                                                Log Out
                                            </Link>
                                        )}
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            {isNurse && <ActionNeeded />}
        </Wrapper>
    );
};
