import { useEffect, useRef, useState } from 'react';

import { makeWebappClient } from '@clh/web-client';

import { newRelic } from './newRelic';
import Modal from './shared-components/modal';

const SYNC_VERSION_MODAL_DOM_ID = 'sync-version';

const VersionPing = () => {
    const apiClient = makeWebappClient({});
    const ref = useRef<HTMLButtonElement>(null);
    const intervalId = useRef<ReturnType<typeof global.setInterval>>();
    const [didDecline, setDidDecline] = useState(false);

    const checkVersion = () => {
        apiClient
            .bootstrapControllerGetBootstrapConfig()
            .then(({ config }) => {
                if (config.gitCommit !== GIT_COMMIT) {
                    newRelic.addPageAction('FE_VERSION_OUT_OF_SYNC');

                    if (didDecline) {
                        return;
                    }

                    ref.current?.click();
                }
            })
            .catch(newRelic.noticeError);
    };

    const beginInterval = () => {
        if (!didDecline) {
            intervalId.current = setInterval(checkVersion, 60 * 1000);
        }
    };

    const stopInterval = () => {
        if (intervalId.current) {
            clearInterval(intervalId.current);
        }
    };

    const toggleInterval = () => {
        if (document.hidden) {
            stopInterval();
        } else {
            beginInterval();
        }
    };

    useEffect(() => {
        beginInterval();

        document.addEventListener('visibilitychange', toggleInterval);

        return () => {
            document.removeEventListener('visibilitychange', toggleInterval);
        };
    }, []);

    useEffect(() => {
        if (didDecline) {
            stopInterval();
        }

        return () => stopInterval();
    }, [intervalId, didDecline]);

    const reload = () => {
        window.location.assign(`?cache-bust=${new Date().getTime()}`);
    };

    return (
        <>
            <Modal
                body="A new version of Humla Health is available. Would you like to reload to get the latest version?"
                id={SYNC_VERSION_MODAL_DOM_ID}
                title="New Version Available"
                confirmText="Reload"
                onCancel={() => setDidDecline(true)}
                onConfirm={() => reload()}
            />
            <button
                ref={ref}
                className="me-2 d-none"
                data-bs-toggle="modal"
                data-bs-target={`#${SYNC_VERSION_MODAL_DOM_ID}`}
            />
        </>
    );
};

export default VersionPing;
